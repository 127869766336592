/**
 * layouts > footer
 */

.footer {
    background-color: var(--footer-background-color, $footer-background-color);
    font-size: 0.825rem;

    &__body {
        padding-bottom: $spacer * 1.25;
        padding-top: $spacer * 1.25;
        text-align: center;

        @include breakpoint(md) {
            align-items: center;
            display: flex;
            justify-content: center;
            text-align: left;
        }

    }

    &__brand {
        display: inline-block;
        width: 5rem;
    }

    &__asset {
        display: block;
        fill: currentColor;
    }

    &__content {
        @include breakpoint(md) {
            margin-left: $spacer * 1.75;
        }
    }
}
