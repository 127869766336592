/**
 * Base > Typography
 */

a {
    @include transition($transition-color);

    color: $link;
    text-decoration: none;

    &:hover {
        color: $link-hover;
        text-decoration: underline;
    }
}

h1,
h2,
h3,
h4,
h5 {
    @extend %heading;
    @extend %fluidtype;
}

h1 {
    --fluid-type-min: 1.875rem;
    --fluid-type-max: 4.5rem;
}

h2 {
    --fluid-type-min: 1.5rem;
    --fluid-type-max: 2.5rem;
}

h3 {
    --fluid-type-min: 1.275rem;
    --fluid-type-max: 1.75rem;
}

h4 {
    --fluid-type-min: 1.125rem;
    --fluid-type-max: 1.25rem;
}

h5 {
    --fluid-type-min: 1.175rem;
    --fluid-type-max: 1.175rem;
}

/* stylelint-disable selector-no-qualifying-type */

h2[id],
h3[id],
h4[id],
h5[id] {
    font-weight: 600;
}

h2[id] {
    border-top: 0.125rem solid var(--border-color, $border-color);
    font-weight: 600;
    margin-top: $spacer * 2;
    padding-top: $spacer * 1.75;

    &:first-of-type {
        border: 0;
        margin-top: 0;
        padding-top: 0;
    }
}

h3[id],
h4[id] {
    margin-top: $spacer * 1.75;
}

/* stylelint-enable selector-no-qualifying-type */

p {
    margin-bottom: $paragraph-margin-bottom;
    margin-top: 0;
}

.lead {
    color: var(--base-copy-lighter, $lead-color);
    font-size: 1.25rem;
    font-weight: 300;
}

.meta {
    font-size: 0.825rem;
    font-weight: 700;
    text-transform: uppercase;
}
