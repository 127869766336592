/**
 * layouts > button
 */

.button {
    @include transition($transition-background-color);

    border: 0;
    border-radius: $button-radius;
    cursor: pointer;
    display: inline-block;
    padding: $spacer * 0.5 $spacer * 1;
    text-align: center;
    user-select: none;
    vertical-align: middle;

    &--primary {
        background-color: $link;
        color: $white;

        &:hover {
            background-color: shade-color($link, 25%);
            color: $white;
        }
    }

    &__icon {
        display: block;
        font-size: 1em;
        height: 1em;
        width: 1em;
    }
}
