/**
 * layouts > hero
 */

.hero {
    @include transition($transition-background-color);
    background-color: var(--hero-background-color, $hero-background-color);

    &__body {
        display: flex;
        flex-direction: column;
        padding-bottom: $hero-padding-y / 2;
        padding-top: $hero-padding-y / 2;

        @include breakpoint(md) {
            align-items: flex-end;
            flex-direction: row;
            justify-content: space-between;
        }

        @include breakpoint(lg) {
            padding-bottom: $hero-padding-y;
            padding-top: $hero-padding-y * 2;
        }
    }

    &__heading {
        margin-bottom: 0;
    }

    &__lead {
        margin-top: $hero-lead-margin-y;
    }

    &__main {
        margin-bottom: $spacer;

        @include breakpoint(lg) {
            margin-bottom: 0;
            max-width: calc(100% - #{$aside-width});
            padding-right: $spacer * 4;
        }
    }

    &__aside {
        @include breakpoint(md) {
            margin-left: $spacer * 4;
        }
    }
}
