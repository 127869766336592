/**
 * layouts > nav
 */

.nav {
    $b: &;

    &__list {
        list-style: none;
        margin-bottom: 0;
        padding: 0;
        width: 100%;

        @include breakpoint(sm) {
            display: flex;
            flex-wrap: wrap;
        }

        @include breakpoint(lg) {
            flex-wrap: nowrap;
        }
    }

    &__item {
        font-weight: $nav-item-font-weight;
    }

    &__link {
        display: block;
        padding-left: $nav-link-padding-x;
        padding-right: $nav-link-padding-x;
    }

    &__link--active {
        font-weight: $nav-link-active-font-weight;
    }

    &--vertical {
        #{$b}__list {
            @include breakpoint(md) {
                flex-direction: column;
            }
        }

        #{$b}__item {
            @include breakpoint(md) {
                margin-left: 0;
                margin-right: 0;
            }
        }

        #{$b}__link {
            padding-left: 0;
            padding-right: 0;
        }
    }

    &--toc {
        > #{$b}__list > #{$b}__item {
            margin-bottom: $spacer * 0.875;
        }

        #{$b}__item #{$b}__item {
            font-size: 0.925rem;
            font-weight: 400;
            margin-top: $spacer * 0.125;
        }

        #{$b}__link {
            color: $nav-toc-link-color;

            &:hover {
                color: $nav-toc-link-hover-color;
            }
        }

        #{$b}__item #{$b}__item #{$b}__link {
            color: var(--base-copy-lighter, $nav-toc-sub-link-color);

            &:hover {
                color: $nav-toc-sub-link-hover-color;
            }
        }
    }
}
