/**
 * layouts > intro
 */

.intro {
    &__body {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-top: $spacer * 2;
    }

    &__content {
        margin-bottom: clamp(#{$spacer} * 2, 4vw, #{$spacer} * 5);
        margin-top: clamp(#{$spacer} * 2, 4vw, #{$spacer} * 5);
        text-align: center;
    }

    &__brandLogo {
        margin-bottom: $spacer * 0.75;
    }

    &__lead {
        margin-bottom: 0;
    }
}
