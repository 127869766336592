/**
 * layouts > masthead
 */

.masthead {

    @at-root #js-masthead__nav:not(.mm-spn) {
        display: none;

        @include breakpoint(lg) {
            display: block;
        }
    }

    @at-root .js-masthead__toggle {
        @include frame($frame-height: $masthead-nav-toggle-size, $frame-width-factor: 0.5, $background: var(--mobile-nav-toggle-background-color, $white), $is-scalable: true);

        align-items: center;
        bottom: $masthead-nav-toggle-bottom-pos;
        display: flex;
        font-weight: $masthead-nav-toggle-font-weight;
        justify-content: center;
        padding-bottom: $masthead-nav-toggle-padding-y;
        padding-left: $masthead-nav-toggle-padding-x;
        padding-right: $masthead-nav-toggle-padding-x;
        padding-top: $masthead-nav-toggle-padding-y;
        position: fixed;
        right: $masthead-nav-toggle-right-pos;

        &:hover {
            text-decoration: none;
        }

        @include breakpoint(md) {
            display: none;
        }
    }

    &__body {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: $masthead-body-padding-y;
        padding-top: $masthead-body-padding-y;
        position: relative;

        @include breakpoint(lg) {
            text-align: left;
        }
    }

    &__brand {
        color: var(--base-copy, $body-color);
        display: inline-block;
        font-size: 1.625rem;
        font-weight: $masthead-brand-font-weight;

        &:hover {
            color: $masthead-brand-link-hover-color;
        }
    }

    &__wrapper {
        display: flex;

        @include breakpoint(md) {
            width: 100%;
        }

        @include breakpoint(lg) {
            width: auto;
        }
    }

    &__color-mode {
        align-items: center;
        display: flex;
        justify-content: flex-end;

        @include breakpoint(md) {
            position: absolute;
            right: 0;
            top: $masthead-body-padding-y;
        }

        @include breakpoint(lg) {
            margin-left: $spacer * 1.5;
            position: static;
        }
    }

    &__nav {
        // display: none;

        @include breakpoint(md) {
            // display: block;
            flex-grow: 1;
            margin-left: $spacer * -0.675;
            margin-top: 0;
        }

        @include breakpoint(lg) {
            flex-grow: 0;
            margin-left: auto;
            width: auto;
        }
    }

    &__nav-link {
        color: var(--base-copy, $body-color);
        padding-bottom: $spacer * 0.5;
        padding-left: 0;
        padding-right: 0;
        padding-top: $spacer * 0.5;

        @include breakpoint(sm) {
            padding-left: $nav-link-padding-x;
            padding-right: $nav-link-padding-x;
        }
    }
}

