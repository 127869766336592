/**
 * layouts > icon
 */

.icon {
    display: block;
    fill: none;
    stroke: currentColor;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: $icon-stroke-width;
}
