/**
 * base > base
 */

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    @include transition($transition-background-color);

    background-color: var(--matte, $gray-100);
    color: var(--base-copy, $body-color);
    font-family: $base-font-family;
    font-size: $base-font-size;
    font-weight: $base-font-weight;
    line-height: $base-line-height;
    margin: 0;
    -webkit-tap-highlight-color: rgba(#000, 0);
    text-size-adjust: 100%;
}

img {
    height: auto;
    max-width: 100%;
}

// Hide mouse-initiated focus styles
:focus:not(:focus-visible) {
    outline: 0;
}

// Show keyboard-initiated focus styles
:focus-visible {
    outline: 2px solid $gray-500;
}

b,
strong {
    font-weight: 600;
}

ol,
ul {
    padding-left: $spacer * 2;
}

ol,
ul,
dl {
    margin-bottom: $spacer;
    margin-top: 0;
}

dt {
    font-weight: 600;
}

dd {
    margin-left: 0;
    margin-top: $spacer * 0.5;
}

dd + dt {
    margin-top: $spacer;
}

pre,
code,
kbd,
samp {
    font-family: $font-family-code;
}

pre {
    color: $gray-400;
    display: block;
    margin-bottom: $spacer;
    margin-top: 0;
    overflow: auto;
}

code {
    color: $code-color;
    font-size: $font-size-code;
    word-wrap: break-word;
}

kbd {
    background-color: $gray-200;
    border-radius: 0.25rem;
    color: inherit;
    font-size: $font-size-code;
    padding: $spacer * 0.25 $spacer * 0.5;

    kbd {
        font-size: 1em;
        padding: 0;
    }
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
    border: 0 !important;
    clip: rect(0, 0, 0, 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important;
}

.fpo {
    background-color: var(--fpo-background-color, $fpo-background-color);
    display: inline-block;
    padding: $spacer * 0.0625 $spacer * 0.275;
}
