/**
 * layouts > cover
 */

.cover {
    @include transition($transition-background-color);
    background-color: var(--cover-background-color, $cover-background-color);

    &__body {
        display: flex;
        padding-bottom: $spacer * 4;
        padding-top: $spacer * 4;
    }
}

