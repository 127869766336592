/**
 * layouts > color-mode
 */

.color-mode {
    $b: &;

    &__body {
        background-color: $color-mode-background;
        border-radius: 0.5rem;
        display: flex;
        padding: $spacer * 0.25;
        width: 5rem;
    }

    &__button {
        align-items: center;
        background-color: transparent;
        border: 0;
        border-radius: 0.25rem;
        cursor: pointer;
        display: flex;
        flex: 1 0 0%;
        height: 2rem;
        justify-content: center;
        line-height: 1;
        padding: 0;
        position: relative;
        text-align: center;
        width: 2.5rem;
        z-index: 0;
    }

    &__background {
        @include transition($transition-background-color);
        background-color: $color-mode-active-background;
        border-radius: 0.25rem;
        display: none;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;

    }

    &__icon {
        height: 1.25rem;
        stroke: $color-mode-icon-color;
        stroke-width: $color-mode-icon-stroke-width;
        width: 1.25rem;
    }
}

