/**
 * layouts > backdrop
 */

.backdrop {
    &::before,
    &::after {
        bottom: auto;
        content: '';
        display: block;
        position: absolute;
        top: 0;
    }

    &::before {
        background-image: linear-gradient(to bottom, transparent, var(--matte, $gray-100)), url("../img/culture-shed.jpg");
        background-position: 0 100%, 0 0;
        background-repeat: no-repeat;
        background-size: 100% 300px, cover;
        filter: grayscale(1) opacity(0.0625);
        height: 58.944%;
        width: 100%;
        z-index: -4;
    }

    &::after {
        background-image: escape-svg($backdrop-shape-circle), escape-svg($backdrop-shape-squiggles);
        background-position: -60% -50%, 130% -40%;
        background-repeat: no-repeat;
        background-size: 48%, 44%;
        left: 0;
        padding-top: 100%;
        width: 100%;
        z-index: -3;
    }
}
