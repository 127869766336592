/**
 * layouts > button
 */

.link {
    $b: &;

    color: $link;

    &__icon {
        height: 1em;
        stroke: currentColor;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: $icon-stroke-width;
        vertical-align: -0.125em;
        width: 1em;
    }

    &--icon {
        font-weight: 600;
    }

    &--arrow {
        #{$b}__icon {
            @include transition(250ms transform ease-in-out);
        }

        &:hover {
            #{$b}__icon {
                @include transition(250ms transform ease-in-out);
                transform: translateX(0.25em);
            }
        }
    }

    &--semibold {
        font-weight: 600;
    }
}
