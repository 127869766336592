/**
 * base > root
 */

:root {
    --mm-spn-item-height: 2.5rem;
    font-size: $root-font-size;
    @media (prefers-reduced-motion: no-preference) {
        scroll-behavior: smooth;
    }
}

:root[color-mode="light"] {
    --matte: #{$gray-100};
    --hero-background-color: #{$hero-background-color};
    --cover-background-color: #{$cover-background-color};
    --footer-background-color: #{$footer-background-color};
    --chapter-background-color: #{$chapter-background-color};
    --fpo-background-color: #{$fpo-background-color};
    --entry-background-color: #{$entry-background-color};
    --entry-box-shadow: #{$entry-box-shadow};
    --torso-background-color: #{$torso-background-color};
    --border-color: #{$border-color};
    --base-copy: #{$body-color};
    --base-copy-lighter: #{$gray-700};
    --mobile-nav-toggle-background-color: #{$masthead-nav-toggle-background-color-light};
    --mobile-nav-background-color: #{$white};

    .color-mode__button--light .color-mode__background {
        display: block;
    }

    .color-mode__button--light .color-mode__icon {
        stroke: color-contrast($color-mode-active-background);
    }
}

:root[color-mode="dark"] {
    --matte: #{$gray-900};
    --hero-background-color: #{$hero-background-color-dark};
    --cover-background-color: #{$cover-background-color-dark};
    --footer-background-color: #{$footer-background-color-dark};
    --chapter-background-color: #{$chapter-background-color-dark};
    --fpo-background-color: #{$fpo-background-color-dark};
    --entry-background-color: #{$entry-background-color-dark};
    --entry-box-shadow: none;
    --torso-background-color: #{$torso-background-color-dark};
    --border-color: #{$border-color-dark};
    --base-copy: #{$body-color-dark};
    --base-copy-lighter: #{$gray-300};
    --mobile-nav-toggle-background-color: #{$masthead-nav-toggle-background-color-dark};
    --mobile-nav-background-color: #{$black};

    .color-mode__button--dark .color-mode__background {
        display: block;
    }

    .color-mode__button--dark .color-mode__icon {
        stroke: color-contrast($color-mode-active-background);
    }

    .backdrop::after {
        opacity: 0.25;
    }
}
