/**
 * layouts > torso
 */

.torso {
    background-color: var(--torso-background-color, $torso-background-color);

    &__body {
        @include breakpoint(lg) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__main {
        padding-bottom: $spacer * 3;
        padding-top: $spacer * 3;

        @include breakpoint(lg) {
            max-width: calc(100% - #{$aside-width});
            padding-bottom: $spacer * 5;
            padding-right: $spacer * 4;
            padding-top: $spacer * 5;
        }
    }

    &__aside {
        margin-left: -$container-padding-x;
        margin-right: -$container-padding-x;
        padding-bottom: $spacer * 3;
        padding-left: $container-padding-x;
        padding-right: $container-padding-x;
        padding-top: $spacer * 3;

        @include breakpoint(lg) {
            display: block;
            flex-basis: $aside-width;
            flex-shrink: 0;
            margin-left: 0;
            margin-right: 0;
            padding-bottom: $spacer * 5;
            padding-left: $spacer * 2;
            padding-top: $spacer * 5;
        }
    }

    &__nav--toc-stuck {
        position: sticky;
        top: 1rem;
    }
}
