/**
 * layouts > container
 */

.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: $container-padding-x;
    padding-right: $container-padding-x;
    width: 100%;
}

@each $bp, $container-max-width in $container-max-widths {
    .container--#{$bp} {
        @extend .container;
        max-width: $container-max-width;
    }
}
