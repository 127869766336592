/**
 * base > animations
 */

@keyframes gradientReverseY {
    0% {
        background-position: 50% 0%;
    }

    50% {
        background-position: 50% 100%;
    }

    100% {
        background-position: 50% 0%;
    }
}
